@import '../../styles/index';

.base {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  padding: 16px;
  max-width: 320px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

.text {
  color: #111c24;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  max-width: 80%;
}

.icon {
  height: 24px;
  width: 24px;
  color: #c7125c;
  cursor: pointer;
}

.pin {
  position: absolute;
  display: block;
  color: #fff;
}

.left {
  transform: rotate(0deg);
}

.right {
  transform: rotate(180deg);
}

.top {
  transform: rotate(90deg);
}

.bottom {
  transform: rotate(270deg);
}

.left-center {
  left: -16px;
  top: calc(50% - 16px);
}

.left-top {
  left: -16px;
  top: calc(25% - 16px);
}

.left-bottom {
  left: -16px;
  top: calc(75% - 16px);
}

.right-center {
  right: -16px;
  top: calc(50% - 16px);
}

.right-top {
  right: -16px;
  top: calc(25% - 16px);
}

.right-bottom {
  right: -16px;
  top: calc(75% - 16px);
}

.bottom-center {
  bottom: -16px;
  right: calc(50% - 16px);
}

.bottom-top {
  bottom: -16px;
  right: calc(75% - 16px);
}

.bottom-bottom {
  bottom: -16px;
  right: calc(25% - 16px);
}

.top-center {
  top: -16px;
  right: calc(50% - 16px);
}

.top-top {
  top: -16px;
  right: calc(75% - 16px);
}

.top-bottom {
  top: -16px;
  right: calc(25% - 16px);
}
